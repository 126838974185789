<template>
  <div class="auth">
    <div class="auth__header">
      <a href="https://leasing-trade.ru" class="auth__header__link" target="_blank">Вернуться на сайт</a>
      <div class="auth__logo">
        <img src="/logo.png" alt="Лизинг трейд"/>
      </div>
      <a href="https://leasing-trade.ru" class="auth__header__link auth__header__link--hidden">Вернуться на сайт</a>
    </div>
    <div style="margin-bottom: 30px" class="auth__body">
      <form class="auth__form">
        <h1 class="auth__form__title">Регистрация</h1>
        <!-- <div class="auth__form__input">
          <label for="name">Наименование организации</label>
          <input type="text" v-model="name" id="name">
        </div> -->
        <div class="auth__form__input">
          <label for="inn">ИНН организации</label>
          <input type="number" v-model="inn" id="inn" disabled>
        </div>
        <div class="auth__form__input">
          <label for="email">E-mail</label>
          <input type="email" v-model="email" id="email">
        </div>
        <div class="auth__form__input">
          <label for="phone">Номер телефона</label>
          <input type="tel" v-model="phone" id="phone">
          <label for="phone" style="margin-top: 15px">На указанный номер будет выслан код подтверждения</label>
        </div>
        <div class="auth__form__input auth__form__input--check">
          <input type="checkbox" v-if="!smsCode" v-model="check" name="check" id="check">
          <input type="checkbox" v-else disabled v-model="check" name="check" id="check">
          <label for="check"><span>Я соглашаюсь и предоставляю свое согласие на <a target="_blank" href="https://leasing-trade.ru/documents/soglashenie.pdf">обработку моих персональных данных</a>.</span></label>
        </div>
        <div v-if="smsCode" class="auth__form__input">
          <label for="code">SMS-код</label>
          <input type="code" v-model="code" id="code">
          <label v-if="timeLeft === 0" class="auth__form__input__retry" @click.prevent="sendSms">Получить код повторно</label>
          <label v-else class="auth__form__input__retry">Получить код повторно будет доступно через {{ this.timeLeft }} с</label>
        </div>
        <div class="auth__form__actions">
          <button v-if="smsCode" class="btn" @click.prevent="regPartner">Регистрация</button>
          <button v-else-if="!smsCode && check && inn && phone && email" class="btn" @click.prevent="sendSms">Получить SMS-код</button>
          <button v-else disabled class="btn btn--gray">Получить SMS-код</button>
          <router-link :to="{ name: 'auth'}">Уже есть аккаунт</router-link>
        </div>
      </form>
      <div class="auth__body__back">
        <AuthBack/>
      </div>
    </div>
  </div>
</template>

<script>
import AuthBack from '../components/svg/AuthBack'
import axios from 'axios'
import REG_PARTNER from '@/graphql/mutations/RegPartner.gql'
import REG_SMS_PARTNER from '@/graphql/mutations/RegSmsPartner.gql'
import GraphQLHelper from '../helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'smsRegistration',
  data: () => {
    return {
      // name: '',
      inn: '',
      email: '',
      phone: '',
      check: '',
      code: '',
      smsCode: '',
      timeLeft: 0
    }
  },
  components: {
    AuthBack
  },
  beforeMount () {
    this.inn = this.$store.state.partnerInn || ''
  },
  methods: {
    validateFields () {
      if (this.inn.length !== 10 && this.inn.length !== 12) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'ИНН должен содержать 10 или 12 символов'
        })
        return false
      }
      // if (!this.name) {
      //   this.$notify({
      //     group: 'lsg-notify',
      //     duration: 10000,
      //     text: 'Введите наименование организации'
      //   })
      //   return false
      // }
      if (!this.email) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Введите E-mail'
        })
        return false
      }
      if (!this.phone) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Введите номер телефона'
        })
        return false
      }
      if (!this.check) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Не все поля заполнены'
        })
        return false
      }
      return true
    },
    async sendSms () {
      if (!this.validateFields()) return
      this.timer()
      const smsCode = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000
      this.smsCode = smsCode
      const text = `Vash code: ${smsCode} dlya registratsii`
      const BASE_URL = `${process.env.VUE_APP_HTTP}`
      const url = `${BASE_URL}/sms`

      this.spareRegPartner()

      axios.post(url, { text: text, phone: this.phone }).then(res => {
        if (res.status === 200) {
          this.$notify({
            group: 'lsg-notify',
            duration: 10000,
            text: 'SMS-код отправлен на указанный вами номер'
          })
        } else {
          this.$notify({
            group: 'lsg-notify',
            duration: 10000,
            text: 'Ошибка при отправке кода'
          })
        }
      })
    },
    async regPartner () {
      if (!this.validateFields()) return
      if (this.smsCode === +this.code) {
        await this.$apollo.mutate({
          mutation: REG_SMS_PARTNER,
          variables: {
            inn: this.inn,
            phone: this.phone,
            email: this.email
          },
          update: async (store, { data: { regSmsPartner } }) => {
            if (regSmsPartner) {
              this.$notify({
                group: 'lsg-notify',
                duration: 10000,
                text: 'Вам на email отправлено письмо с инструкциями по входу в систему'
              })
              this.$router.push('/auth')
            } else {
              this.$notify({
                group: 'lsg-notify',
                duration: 10000,
                text: 'Ошибка при регистрации'
              })
            }
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
        // this.$notify({
        //   group: 'lsg-notify',
        //   duration: 10000,
        //   text: 'Неверный SMS-код'
        // })
      } else {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Неверный SMS-код'
        })
      }
    },
    async spareRegPartner () {
      await this.$apollo.mutate({
        mutation: REG_PARTNER,
        variables: {
          inn: this.inn
        },
        update: (store, { data: { regPartner } }) => {
          if (regPartner) {
            this.$notify({
              group: 'lsg-notify',
              duration: 10000,
              text: 'Вам на email отправлено письмо с инструкциями по входу в систему'
            })
            this.$router.push('/auth')
          }
        }
      })
    },
    timer () {
      this.timeLeft = 120
      let timer = setInterval(() => {
        if (this.timeLeft === 0) {
          clearInterval(timer)
        } else {
          this.timeLeft--
        }
      }, 1000)
    }
  }
}
</script>

<style lang="stylus" scoped>
.auth__form__input--check {
  display flex
  flex-direction row
  justify-content center
  align-items center

  > input {
    min-width 15px
    min-height 15px
    margin-right 15px
  }

  > label {
    margin 0
  }
}

.auth__form__input__retry {
  margin-top 25px

  &:hover {
    text-decoration underline
    cursor pointer
  }
}
</style>
